import {Outlet} from '@remix-run/react';
import type {LinksFunction} from '@remix-run/cloudflare';

import stylesheet from '@/stylesheets/partners/partners.css?url';

export const links: LinksFunction = () => [
  {
    rel: 'stylesheet',
    href: stylesheet,
  },
];

export default function PartnersDirectoryLayout() {
  return <Outlet />;
}
